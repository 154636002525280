<template>
<loader v-bind="{ loading }" text="Loading Users">
  <columns>
    <column>
      <page-heading
        heading="New User"
        sub-heading="Add a new user to your account."
      />
    </column>
    <column class="is-narrow is-flex is-align-items-center">
      <router-link :to="{ name: 'users' }">Back</router-link>
    </column>
  </columns>
  <form>
    <columns>
      <column class="is-2">
        <switch-input
          classes="is-medium is-rounded"
          description="Passive users can only view information and not create or update any resources"
          :value="user.is_passive"
          @input="togglePassive">
          Passive User
        </switch-input>
      </column>
      <transition name="fade" mode="out-in">
        <column v-if="user.is_passive">
          <data-selector
            tag-class="is-medium is-rounded"
            classes="is-medium is-rounded"
            delete-button-class="is-medium is-rounded is-danger"
            description="A list of customers that this user will have access to view. If no customers are selected NO customers will be made available."
            multiple
            searchable
            :items="customer_list"
            value-key="id"
            label-key="name"
            :value="user.customers"
            @input="updateCustomers">
            Accessible Customers <span class="has-text-weight-light">(optional)</span>
          </data-selector>
        </column>
      </transition>
      <transition name="fade" mode="out-in">
        <column v-if="user.is_passive">
          <data-selector
            tag-class="is-medium is-rounded"
            classes="is-medium is-rounded"
            delete-button-class="is-medium is-rounded is-danger"
            description="A list of Customer Locations that this user will have access to view. If no locations are selected all locations will be made available."
            multiple
            searchable
            :items="availableLocations"
            value-key="id"
            label-key="name"
            :value="user.locations"
            @input="updateLocations">
            Accessible Locations <span class="has-text-weight-light">(optional)</span>
          </data-selector>
        </column>
      </transition>
      <transition name="fade" mode="out-in">
        <column v-if="!user.is_passive">
          <data-selector
            tag-class="is-medium is-rounded"
            classes="is-medium is-rounded"
            delete-button-class="is-medium is-rounded is-danger"
            description="A list of branches that this user will work under"
            multiple
            searchable
            :items="branch_list"
            value-key="id"
            label-key="name"
            :value="user.branches"
            @input="updateBranches">
            Branches
          </data-selector>
        </column>
      </transition>
      <transition name="fade" mode="out-in">
        <column v-if="!user.is_passive">
          <checkbox-input
            :value="user.automatic_teams"
            @input="toggleAutomaticTeams"
            :description="'When checked a dedicated team will be created for this user under each branch they are assigned to.'">
            Automatically Create Teams
          </checkbox-input>
        </column>
      </transition>
      <transition name="fade" mode="out-in">
        <column v-if="!user.is_passive">
          <data-selector
            tag-class="is-medium is-rounded"
            classes="is-medium is-rounded"
            delete-button-class="is-medium is-rounded is-danger"
            description="A list of teams that this user will work under"
            multiple
            searchable
            :items="teams"
            value-key="id"
            label-key="name"
            :value="user.teams"
            @input="updateTeams">
            Add to Existing Teams
          </data-selector>
          <p class="help is-danger" v-if="user.branches.length && !teams.length">There are no teams associated with this branch.</p>
        </column>
      </transition>
    </columns>
    <columns>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :value="user.name"
          @input="updateName"
          :error="$root.errors.name"
          required>
          Name
        </text-input>
      </column>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :value="user.surname"
          @input="updateSurname"
          :error="$root.errors.surname"
          required>
          Last Name
        </text-input>
      </column>
    </columns>
    <columns>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :value="user.email"
          @input="updateEmail"
          :error="$root.errors.email"
          required>Email Address
        </text-input>
      </column>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :value="user.profile.mobile"
          @input="updateMobile"
          :error="$root.errors.mobile">
          Mobile Number <span class="has-text-weight-light">(optional)</span>
        </text-input>
      </column>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :value="user.designation"
          @input="updateDesignation"
          :error="$root.errors.designation">
          Designation <span class="has-text-weight-light">(optional)</span>
        </text-input>
      </column>
    </columns>

    <transition name="fade" mode="out-in">
      <columns v-if="!user.is_passive">
        <column>
          <permissions-builder :permissions="permissions"/>
        </column>
      </columns>
    </transition>
    
    <columns>
      <column>
        <notification-preferences/>
      </column>
    </columns>

    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="creating"
          @submit="createUser">
          Create User
        </submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { team as backend } from '@/api'
import { permissions as permissionBackend } from '@/api'
import PermissionsBuilder from './partials/PermissionsBuilder.vue'
import NotificationPreferences from './partials/NotificationPreferences.vue'
import flatMap from 'lodash/flatMap'

export default {

  components: {
    PermissionsBuilder,
    NotificationPreferences
  },

  data: () => ({
    loading: true,
    creating: false,
    teams: [],
    permissions: []
  }),

  async beforeCreate() {
    await permissionBackend.loadPermissions(({data}) => {
      this.permissions = data
    }, () => {})
    await this.$store.dispatch('branch/loadList')
    await this.$store.dispatch('customer/loadCustomerList')
    this.loading = false
  },

  created() {
    this.clearUser()
  },

  beforeDestroy() {
    this.clearUser()
  },

  methods: {
    ...mapMutations('user', [
      'updateName',
      'updateSurname',
      'updateEmail',
      'updateMobile',
      'updateBranches',
      'updateCustomers',
      'updateLocations',
      'clearUser',
      'updateTeams',
      'updateDesignation',
      'toggleAutomaticTeams',
      'togglePassive'
    ]),
    createUser() {
      this.creating = true
      this.$store.dispatch('user/createUser').then(() => {
        this.$toast.success('User successfully created')
        this.creating = false
        this.$router.push({
          name: 'users'
        })
      }).catch((error) => {
        if(error.response.status === 406) {
          this.$confirm({
            title: 'Account Limit Reached',
            message: error.response.data.limits
          })
        }
        this.creating = false
      })
    },
    loadTeamsForBranches() {
      if(this.user.branches.length) {
        backend.loadTeamList({
          branch_ids: this.user.branches.map(branch => branch.id)
        }, ({data}) => {
          this.teams = data
        }, () => {})
      } else {
        this.teams = []
        this.updateTeams([])
      }
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('billing', [
      'subscription'
    ]),
    ...mapGetters('branch', [
      'branch_list'
    ]),
    ...mapGetters('customer', ['customer_list']),
    authUser() {
      return this.$store.getters['auth/user']
    },
    isSuperUser() {
      return this.subscription.account.user_id === this.user.id
    },
    authIsSuperUser() {
      return this.subscription.account.user_id === this.authUser.id
    },
    isAnAdmin() {
      return this.user.abilities.filter(permission => permission.name === '*').length
    },
    availableLocations() {
      return flatMap(this.user.customers, 'locations')
    }
  },

  watch: {
    'user.branches': 'loadTeamsForBranches'
  }

}
</script>