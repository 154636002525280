<template>
<div>
    <columns>
        <column>
            <h4 class="title is-4">
                Notification Preferences
            </h4>
        </column>
    </columns>
    <columns>
        <column class="is-narrow">
            <switch-input
                classes="is-rounded"
                :value="user.profile.notification_preferences.checklist_completed"
                @input="toggleChecklistCompletionNotification">
                Checklist Completion Report
            </switch-input>
        </column>
        <column class="is-narrow">
            <switch-input
                classes="is-rounded"
                :value="user.profile.notification_preferences.work_order_completed"
                @input="toggleWorkOrderCompletionNotification">
                Work Order Completion Report
            </switch-input>
        </column>
        <column class="is-narrow">
            <switch-input
                classes="is-rounded"
                :value="user.profile.notification_preferences.work_order_opened"
                @input="toggleWorkOrderOpenedNotification">
                Work Order Opened
            </switch-input>
        </column>
        <column class="is-narrow">
            <switch-input
                classes="is-rounded"
                :value="user.profile.notification_preferences.recurring_work_order_skipped"
                @input="toggleWorkOrderSkippedNotification">
                Recurring Work Order Skipped
            </switch-input>
        </column>
    </columns>
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

    methods: {
        ...mapMutations('user', [
            'toggleChecklistCompletionNotification',
            'toggleWorkOrderCompletionNotification',
            'toggleWorkOrderOpenedNotification',
            'toggleWorkOrderSkippedNotification',
        ])
    },

    computed: {
        ...mapGetters('user', [
            'user'
        ])
    }

}
</script>